import { ArticleWrapper, Date, Header, InternalContainer, Label, StyledImage, Summary } from './basic-styles';
import { Col, Row } from 'antd';
import { Link, graphql } from 'gatsby';

import { Link18 } from '../components/common/link';
import PageHeader from '../components/common/page-header';
import PublicLayout from '../layout/public-layout';
import React from 'react';
import { getRealNodes } from '../utils/contentFilter';
import { programShortName } from '../siteContent';

type ArticlesProps = {
  data: {
    articleHeader: {
      translation: {
        article_type_value: string;
      };
    };
    articles: {
      nodes: Article[];
    };
  };
};

type Article = {
  directusId: number;
  article_date: string;
  article_slug: string;
  translation: {
    article_title: string;
    article_summary: string;
    language: string;
  };
  article_media: {
    data: {
      full_url: string;
    };
  };
};

// The query used to provide the page data
export const query = graphql`
  query($type: String!, $language: String) {
    articleHeader: directusArticleType(article_type_name: { eq: $type }) {
      translation(language: $language) {
        article_type_value
      }
    }
    articles: allDirectusArticle(
      filter: { article_type: { article_type_name: { eq: $type } }, status: { eq: "published" } }
      sort: { fields: article_date, order: DESC }
    ) {
      nodes {
        directusId
        article_date(formatString: "MMMM DD, YYYY")
        article_slug
        translation(language: $language) {
          article_title
          article_summary
          language
        }
        article_media {
          data {
            full_url
          }
        }
      }
    }
  }
`;

const Articles = ({ data: { articleHeader, articles } }: ArticlesProps) => {
  const articleList = getRealNodes(articles);
  return (
    <PublicLayout seoTitle={articleHeader.translation.article_type_value}>
      <InternalContainer>
        <Row>
          <Col xs={24}>
            <PageHeader
              title=""
              breadcrumb={[
                <Link key="1" to="/">
                  Home
                </Link>,
                `About ${programShortName}: Press and Media`,
              ]}
              fullWidth
              isResponsive
              noShadow
            />
            <Header>{articleHeader.translation.article_type_value}</Header>
          </Col>
        </Row>
        {articleList &&
          articleList.map((article: Article) => {
            const hasImage = article.article_media?.data?.full_url ? true : false;
            return (
              <ArticleWrapper key={article.directusId}>
                <Row justify="start">
                  <Col xs={24}>
                    <Label>{article.translation?.article_title}</Label>
                    <Date>{article.article_date}</Date>
                  </Col>
                  {hasImage && (
                    <Col lg={6} md={24}>
                      <StyledImage
                        src={article.article_media?.data?.full_url}
                        alt={article.translation?.article_title}
                      />
                    </Col>
                  )}
                  <Col lg={hasImage ? 18 : 24} md={24}>
                    <Summary hasImage={hasImage}>
                      {article.translation?.article_summary}
                      <span>
                        ...
                        <Link18 to={article.article_slug} isI18N>
                          Read More
                        </Link18>
                      </span>
                    </Summary>
                  </Col>
                </Row>
              </ArticleWrapper>
            );
          })}
      </InternalContainer>
    </PublicLayout>
  );
};

export default Articles;
